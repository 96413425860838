import { Modal } from "antd";
import React from "react";
import { requestAlerts } from "../../../request";

export const ModalDelete = ({ visible, onCancel, id, onConfirm }) => {
  const deleteAlert = async () => {
    const response = await requestAlerts.deleteAlert(id);
    if (response.status === 204) {
      onCancel();
      onConfirm();
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={"Deletar"}
      onOk={deleteAlert}
    >
      <text style={{ fontWeight: "500", fontSize: 16 }}>
        Deseja realmente excluir este alerta?
      </text>
    </Modal>
  );
};
