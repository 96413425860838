import { Button, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { colors } from "../../Utils/temas";
import { requestAlerts } from "./request";
import { ModalInserir } from "./components/modal/inserirEditar";
import { ModalDelete } from "./components/modal/delete";

export const Alerta = () => {
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);

  const [alertas, setAlertas] = useState([]);

  const [filtros, setFiltros] = useState({});

  const [idMsg, setIdMsg] = useState(null);

  useEffect(() => {
    listAlerts(1, 10);
  }, [filtros]);

  const listAlerts = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await requestAlerts.getAlerts(page, pageSize);
      if (response.status === 200) {
        const { results } = response.data;
        setAlertas(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleEdit = (value) => {
    setOpen(true);
    setIdMsg(value);
  };

  const handleDelete = (value) => {
    setOpenDelete(true);
    setIdMsg(value);
  };

  const columns = [
    {
      title: "Mensagem",
      dataIndex: "mensagem",
    },
    {
      title: "Ações",
      render: (_, render) => (
        <Space size="middle">
          <Tooltip title="Editar">
            <EditOutlined onClick={() => handleEdit(render.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => handleDelete(render.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ padding: 10 }}>
        <Button
          icon={<PlusOutlined />}
          onClick={() => setOpen(true)}
          style={{
            color: "white",
            background: colors.secondary,
            fontWeight: "500",
          }}
          className="botao"
        >
          Inserir
        </Button>
      </div>

      <Table columns={columns} dataSource={alertas} loading={loading} />

      <ModalInserir
        onCancel={() => {
          setOpen(false);
          setIdMsg(null);
        }}
        visible={open}
        onConfirm={() => listAlerts(1, 10)}
        id={idMsg}
      />

      <ModalDelete
        onCancel={() => {
          setOpenDelete(false);
          setIdMsg(null);
        }}
        visible={openDelete}
        id={idMsg}
        onConfirm={() => listAlerts(1, 10)}
      />
    </div>
  );
};
