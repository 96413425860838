import { Input, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { requestAlerts } from "../../../request";

export const ModalInserir = ({ visible, onCancel, onConfirm, id }) => {
  const [mensagem, setMensagem] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id && visible) {
      getAlert();
    }
  }, [id]);

  const postAlert = async () => {
    const response = await requestAlerts.postAlerts({ mensagem: mensagem }, id);
    if (response.status === 201) {
      onCancel();
      setMensagem("");
      onConfirm();
    }
    if (response.status === 200) {
      onCancel();
      setMensagem("");
      onConfirm();
    }
  };

  const getAlert = async () => {
    setLoading(true);
    try {
      const response = await requestAlerts.getAlertId(id);
      if (response.status === 200) {
        const { mensagem } = response.data;
        setMensagem(mensagem);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title={id ? "Editar" : "Inserir"}
      visible={visible}
      onCancel={() => {
        onCancel();
        setMensagem("");
      }}
      onOk={postAlert}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <text>Mensagem:</text>
          <Input
            placeholder="Digite seu alerta"
            onChange={(e) => setMensagem(e.target.value)}
            value={mensagem}
          />
        </>
      )}
    </Modal>
  );
};
