import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

export const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getAlerts = (page, pageSize) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}alerta/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const getAlertId = (id) => {
  const url = `${urlBase}alerta/${id}`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const postAlerts = (data, id) => {
  const url = id ? `${urlBase}alerta/${id}/` : `${urlBase}alerta/`;
  const config = getConfig();

  let response;
  if (id) {
    response = axios.patch(url, data, config);
  } else {
    response = axios.post(url, data, config);
  }
  return response;
};

const deleteAlert = (id) => {
  const url = `${urlBase}alerta/${id}/`;
  const config = getConfig();

  const response = axios.delete(url, config);
  return response;
};

export const requestAlerts = {
  getAlerts,
  getAlertId,
  postAlerts,
  deleteAlert,
};
