/* eslint-disable react-hooks/exhaustive-deps */
import Login from "./Paginas/Login/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "antd/dist/antd.min.css";
import Template from "./Componentes/Template/Template";
import Cartorio from "./Paginas/CadastroCartorio/Cartorio";
import Emolumento from "./Paginas/CadastroEmolumento/Emolumento";
import { EmolumentoItem } from "./Paginas/CadastroEmolumentoItem/EmolumentoItem";
import EmolumentoPeriodo from "./Paginas/CadastroEmolumenoPeriodo/EmolumentoPeriodo";
import SeloGrupo from "./Paginas/CadastroSeloGrupo/SeloGrupo";
import SeloGrupoPai from "./Paginas/CadastroSeloGrupoPai/SeloGrupoPai";
import SeloGrupoFilho from "./Paginas/CadastroSeloGrupoFilho/SeloGrupoFilho";
import ConfigGrupo from "./Paginas/CadastroConfigGrupo/ConfigGrupo";
import ConfigPadrao from "./Paginas/CadastroConfigPadrao/ConfigPadrao";
import ConfigPadraoValor from "./Paginas/CadastroConfigPadraoValor/ConfigPadraoValor";
import GrupoFrases from "./Paginas/CadastroGrupoFrases/GrupoFrases";
import Frases from "./Paginas/CadastroFrases/Frases";
import { Atualizacao } from "./Paginas/Movimentacao/Atualizacao/AtualizacaoLista";
import { AtualizacaoId } from "./Paginas/Movimentacao/Atualizacao-id/AtualizacaoId";
import { CadastroTabela } from "./Paginas/CadastroTabelas/cadastroTabela";
import { CadastroDominio } from "./Paginas/CadastroDominios/cadastroDominio";
import { DetalhesTabela } from "./Paginas/CadastroTabelas/detalhesTabela";
import { VersaoHistorico } from "./Paginas/VersaoHistorico";
import { Procedure } from "./Paginas/CadastroProcedure";
import { View } from "./Paginas/CadastroView";
import { Menu } from "./Paginas/CadastroMenu";
import { MenuId } from "./Paginas/CadastroMenu/menu-id";
import { Sequences } from "./Paginas/CadastroSequence";
import { AtualizacaoRemota } from "./Paginas/Movimentacao/AtualizacaoRemota";
import { Comandos } from "./Paginas/Comandos";
import { EsqueciSenha } from "./Paginas/EsqueciSenha";
import { DetalhesCartorio } from "./Paginas/CadastroCartorio/detalhes";
import {
  DataBaseContext,
  EmolumentContext,
  MonitoringContext,
  MovimentContext,
  RegistryContext,
  ReportContext,
  SystemContext,
  UserContextProvider,
} from "./contexts/usersContext";
import { Home } from "./Paginas/Home";
import { NewConfig } from "./Paginas/ConfigMocked";
import { Relatorios } from "./Paginas/Relatorios";
import { RelatorioGrupo } from "./Paginas/Relatorios/relatoriosGrupos";
import { Aplicacoes } from "./Paginas/Monitoramento/Atualizador";
import { AtualizacaoForcada } from "./Paginas/Movimentacao/AtualizacaoForcada";
import { Alerta } from "./Paginas/Alerta";

const App = () => (
  <BrowserRouter>
    <UserContextProvider>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/Esqueci-senha" element={<EsqueciSenha />} />
        <Route path="/" element={<Template />}>
          <Route path="/" element={<Home />} />

          <Route element={<MovimentContext />}>
            <Route exact path="Atualizacao" element={<Atualizacao />} />
            <Route
              path="Atualizacao/:atualizacaoId"
              element={<AtualizacaoId />}
            />
            <Route
              exact
              path="Atualizar-executavel"
              element={<AtualizacaoRemota />}
            />
            <Route
              exact
              path="Atualizacao-forcada"
              element={<AtualizacaoForcada />}
            />
          </Route>

          <Route element={<DataBaseContext />}>
            <Route path="Dominio" element={<CadastroDominio />} />
            <Route path="Tabela" element={<CadastroTabela />} />
            <Route path="Tabela/:tabelaId" element={<DetalhesTabela />} />
            <Route path="Procedure" element={<Procedure />} />
            <Route path="View" element={<View />} />
            <Route path="Sequences" element={<Sequences />} />
            <Route path="Comandos" element={<Comandos />} />
          </Route>

          <Route element={<SystemContext />}>
            <Route exact path="Config" element={<NewConfig />} />
            <Route exact path="ConfigGrupo" element={<ConfigGrupo />} />
            <Route exact path="ConfigPadrao" element={<ConfigPadrao />} />
            <Route
              exact
              path="ConfigPadraoValor"
              element={<ConfigPadraoValor />}
            />
            <Route exact path="Permissoes" element={<Menu />} />
            <Route exact path="Permissoes/:permissaoId" element={<MenuId />} />
            <Route exact path="GrupoFrase" element={<GrupoFrases />} />
            <Route exact path="Frase" element={<Frases />} />
            <Route
              exact
              path="Versao-historico"
              element={<VersaoHistorico />}
            />
            <Route exact path="Alerta" element={<Alerta />} />
          </Route>

          <Route element={<EmolumentContext />}>
            <Route path="SeloGrupo" element={<SeloGrupo />} />
            <Route path="SeloGrupoPai" element={<SeloGrupoPai />} />
            <Route path="SeloGrupoFilho" element={<SeloGrupoFilho />} />
            <Route path="Emolumento" element={<Emolumento />} />
            <Route path="EmolumentoItem" element={<EmolumentoItem />} />
            <Route path="EmolumentoPeriodo" element={<EmolumentoPeriodo />} />
          </Route>

          <Route element={<RegistryContext />}>
            <Route path="Cartorio" element={<Cartorio />} />
            <Route path="Cartorio/:cartorioId" element={<DetalhesCartorio />} />
          </Route>

          <Route element={<ReportContext />}>
            <Route path="/Relatorio" element={<Relatorios />} />
            <Route path="/RelatorioGrupo" element={<RelatorioGrupo />} />
          </Route>

          <Route element={<MonitoringContext />}>
            <Route path="/Aplicacoes" element={<Aplicacoes />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </UserContextProvider>
  </BrowserRouter>
);

export default App;
